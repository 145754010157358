<nz-layout class="app-layout">
    <nz-sider class="menu-sidebar" [(nzCollapsed)]="isCollapsed" *ngIf="webOne" [ngClass]="!isCollapsed ? 'w-250' : ''">
        <div class="sidebar-top-items">
            <div class="sidebar-logo" *ngIf="!isCollapsed">
                <a [routerLink]="['/welcome']" *ngIf="(isReseller || isReferredUser) && logoUrl">
                    <img class="lightmode_img" [src]="logoUrl" alt="logo">
                    <img class="darkmode_img" [src]="logoUrl" alt="logo">
                </a>
                <a [routerLink]="['/welcome']"
                    *ngIf="(!isReseller && !isReferredUser) || !logoUrl">
                    <img class="lightmode_img" src="./assets/images/sidebar/logo-black.svg" alt="logo">
                    <img class="darkmode_img" src="./assets/images/sidebar/logo_ligth.svg" alt="logo">
                </a>
                 
            </div>
            <div class="sidebar-logo-collapse" *ngIf="isCollapsed">
                <a [routerLink]="['/welcome']" *ngIf="(isReseller || isReferredUser) && collapsedLogoUrl">
                    <img class="lightmode_img" [src]="collapsedLogoUrl" alt="logo">
                    <img class="darkmode_img" [src]="collapsedLogoUrl" alt="logo">
                </a>
                <a [routerLink]="['/welcome']" *ngIf="(!isReseller && !isReferredUser) || !collapsedLogoUrl">
                    <img class="lightmode_img" src="./assets/images/sidebar/logo-collapse-black.svg" alt="logo">
                    <img class="darkmode_img" src="./assets/images/sidebar/logo-collapse-white.svg" alt="logo">
                </a>
            </div>

            <div class="sidebar-divider-container">
                <nz-divider [nzText]="toggleBtn" nzOrientation="right" class="sidebar-divider"></nz-divider>
            </div>
            <ng-template #toggleBtn>
                <span class="trigger trigger-icon" nz-icon [nzType]="isCollapsed ? 'right' : 'left'"
                    (click)="isCollapsed = !isCollapsed"></span>
            </ng-template>

            <div class="side-bar-input">
                <form nz-form>
                    <nz-form-item>
                        <nz-form-control class="header-input">
                            <span class="select-prefix-icon">
                                <img class="sidebar-icon" src="./assets/images/dashboard-icons/team.svg"
                                    alt="dashboard-icon">
                            </span>
                            <nz-select [(ngModel)]="selectedGroupId" #selectedGroup="ngModel" name="selectedGroup"
                                [disabled]="isCollapsed || disableGroup" class="header-input"
                                [ngClass]="{'collpase-d-none' : isCollapsed}"
                                [nzPlaceHolder]="!isCollapsed ? 'Search Groups' : ''"
                                (ngModelChange)="onSubscriptionPlanChanged()">
                                <nz-option [nzValue]="0" [nzLabel]="'Groups (All)'"></nz-option>
                                <nz-option *ngFor="let item of subscriptionPlans" [nzValue]="item.keyInt"
                                    [nzLabel]="item.value"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </form>
            </div>
            <ul nz-menu nzMode="inline">
                <li *ngIf="!isTrialTimeEnded" nz-menu-item nz-tooltip nzTooltipPlacement="right"
                    [nzTooltipTitle]="isCollapsed ? 'Welcome' : ''" [routerLink]="['/welcome']" class="menu-list"
                    [nzSelected]="isActiveRoute('/welcome') || isActiveRoute('/', true)">
                    <div [ngClass]="isCollapsed ? 'active-list-collapse' : 'active-list'"></div>
                    <span>
                        <img class="sidebar-icon" src="./assets/images/sidebar/welcome.svg" alt="dashboard-icon">
                        <img class="sidebar-icon-selected" src="./assets/images/sidebar/welcome-selected.svg"
                            alt="dashboard-icon">
                    </span>
                    <span *ngIf="!isCollapsed " class="menu-content">Welcome</span>
                </li>
                <li nz-menu-item nz-tooltip nzTooltipPlacement="right" [nzTooltipTitle]="isCollapsed ? 'Dashboard' : ''"
                    [routerLink]="['/dashboard']" [nzSelected]="isActiveRoute('/dashboard')" class="menu-list">
                    <div [ngClass]="isCollapsed ? 'active-list-collapse' : 'active-list'"></div>
                    <span>
                        <img class="sidebar-icon" src="./assets/images/sidebar/dashboard.svg" alt="dashboard-icon">
                        <img class="sidebar-icon-selected" src="./assets/images/sidebar/dashboard-selected.svg"
                            alt="dashboard-icon">
                    </span>
                    <span *ngIf="!isCollapsed " class="menu-content">Dashboard</span>
                </li>
                <li nz-menu-item nz-tooltip nzTooltipPlacement="right" [nzTooltipTitle]="isCollapsed ? 'Screens' : ''"
                    [routerLink]="['/display/manage']" class="menu-list" [nzSelected]="isActiveRoute('/display/')">
                    <div [ngClass]="isCollapsed ? 'active-list-collapse' : 'active-list'"></div>
                    <span>
                        <img class="sidebar-icon" src="./assets/images/sidebar/screen.svg" alt="screen-icon">
                        <img class="sidebar-icon-selected" src="./assets/images/sidebar/screen-selected.svg"
                            alt="dashboard-icon">
                    </span>
                    <span *ngIf="!isCollapsed" class="menu-content">Screens</span>
                </li>
                <li nz-menu-item nz-tooltip nzTooltipPlacement="right" [nzTooltipTitle]="isCollapsed ? 'My Media' : ''"
                    class="menu-list" [routerLink]="['/media-app/manage']"
                    [nzSelected]="isActiveRoute('/media-app/manage', true) || isActiveRoute('/user-media-app')">
                    <div [ngClass]="isCollapsed ? 'active-list-collapse' : 'active-list'"></div>
                    <span>
                        <img class="sidebar-icon" src="./assets/images/sidebar/media.svg" alt="media-icon">
                        <img class="sidebar-icon-selected" src="./assets/images/sidebar/media-selected.svg"
                            alt="dashboard-icon">
                    </span>
                    <span *ngIf="!isCollapsed" class="menu-content">My Media</span>
                </li>
                <li nz-menu-item nz-tooltip nzTooltipPlacement="right" [routerLink]="['/media-app/store']"
                    [nzSelected]="isActiveRoute('/media-app/store', true)"
                    [nzTooltipTitle]="isCollapsed ? 'Media App Store' : ''" class="menu-list">
                    <div [ngClass]="isCollapsed ? 'active-list-collapse' : 'active-list'"></div>
                    <span>
                        <img class="sidebar-icon" src="./assets/images/sidebar/media-apps.svg" alt="media-icon">
                        <img class="sidebar-icon-selected" src="./assets/images/sidebar/media-apps-selected.svg"
                            alt="dashboard-icon">
                    </span>
                    <span *ngIf="!isCollapsed" class="menu-content">Media App Store</span>
                </li>
                <li nz-menu-item nz-tooltip nzTooltipPlacement="right" [nzTooltipTitle]="isCollapsed ? 'Playlists' : ''"
                    class="menu-list" [routerLink]="['/display-list/manage']"
                    [nzSelected]="isActiveRoute('/display-list')">
                    <div [ngClass]="isCollapsed ? 'active-list-collapse' : 'active-list'"></div>
                    <span>
                        <img class="sidebar-icon" src="./assets/images/sidebar/playlist.svg" alt="playlist-icon">
                        <img class="sidebar-icon-selected" src="./assets/images/sidebar/playlist-selected.svg"
                            alt="dashboard-icon">
                    </span>
                    <span *ngIf="!isCollapsed" class="menu-content">Playlists</span>
                </li>
                <li nz-menu-item nz-tooltip nzTooltipPlacement="right" [routerLink]="['/broadcast/manage']"
                    [nzSelected]="isActiveRoute('/broadcast')" [nzTooltipTitle]="isCollapsed ? 'Broadcasts' : ''"
                    class="menu-list">
                    <div [ngClass]="isCollapsed ? 'active-list-collapse' : 'active-list'"></div>
                    <span>
                        <img class="sidebar-icon" src="./assets/images/sidebar/broadcast.svg" alt="broadcast-icon">
                        <img class="sidebar-icon-selected" src="./assets/images/sidebar/broadcast-selected.svg"
                            alt="dashboard-icon">
                    </span>
                    <span *ngIf="!isCollapsed" class="menu-content">Broadcasts</span>
                </li>
                <li nz-menu-item nz-tooltip nzTooltipPlacement="right" [nzTooltipTitle]="isCollapsed ? 'Groups' : ''"
                    [routerLink]="['/group/manage']" [nzSelected]="isActiveRoute('/group')" class="menu-list">
                    <div [ngClass]="isCollapsed ? 'active-list-collapse' : 'active-list'"></div>
                    <span>
                        <img class="sidebar-icon" src="./assets/images/sidebar/group.svg" alt="group-icon">
                        <img class="sidebar-icon-selected" src="./assets/images/sidebar/group-selected.svg"
                            alt="dashboard-icon">
                    </span>
                    <span *ngIf="!isCollapsed" class="menu-content">Groups</span>
                </li>
                <li nz-menu-item nz-tooltip nzTooltipPlacement="right"
                    [nzTooltipTitle]="isCollapsed ? 'My Subscription' : ''" [routerLink]="['/subscription/manage']"
                    [nzSelected]="isActiveRoute('/subscription')" class="menu-list" *ngIf="!isReseller">
                    <div [ngClass]="isCollapsed ? 'active-list-collapse' : 'active-list'"></div>
                    <span>
                        <img class="sidebar-icon" src="./assets/images/sidebar/subscription.svg"
                            alt="subscription-icon">
                        <img class="sidebar-icon-selected" src="./assets/images/sidebar/subscription-selected.svg"
                            alt="dashboard-icon">
                    </span>
                    <span *ngIf="!isCollapsed" class="menu-content">My Subscription</span>
                </li>
                <li nz-menu-item nz-tooltip nzTooltipPlacement="right" [nzTooltipTitle]="isCollapsed ? 'Reseller' : ''"
                    [routerLink]="['/subscription/manage']" [nzSelected]="isActiveRoute('/reseller')" class="menu-list"
                    *ngIf="isReseller">
                    <div [ngClass]="isCollapsed ? 'active-list-collapse' : 'active-list'"></div>
                    <span>
                        <img class="sidebar-icon" src="./assets/images/sidebar/subscription.svg"
                            alt="subscription-icon">
                        <img class="sidebar-icon-selected" src="./assets/images/sidebar/subscription-selected.svg"
                            alt="dashboard-icon">
                    </span>
                    <span *ngIf="!isCollapsed " class="menu-content">Reseller</span>
                </li>
                <li nz-menu-item nz-tooltip nzTooltipPlacement="right" [nzTooltipTitle]="isCollapsed ? 'Folders' : ''"
                    [nzSelected]="isActiveRoute('/folder')" [routerLink]="['/folder/manage']" class="menu-list">
                    <div [ngClass]="isCollapsed ? 'active-list-collapse' : 'active-list'"></div>
                    <span>
                        <img class="sidebar-icon" src="./assets/images/sidebar/folder.svg" alt="folder-icon">
                        <img class="sidebar-icon-selected" src="./assets/images/sidebar/folder-selected.svg"
                            alt="dashboard-icon">
                    </span>
                    <span *ngIf="!isCollapsed" class="menu-content">Folders</span>
                </li>
            </ul>
        </div>
        <div class="sidebar-bottom-items pl-2">
            <ul nz-menu nzMode="vertical">
                <!-- <li nz-menu-item nz-tooltip nzTooltipPlacement="right"
                    [nzTooltipTitle]="isCollapsed ? 'What\'s New' : ''" [ngClass]="isCollapsed ? 'pl-3' : 'pl-2'">
                    <span class="pl-1">
                        <img class="sidebar-icon mr-1" src="./assets/images/sidebar/sparkle-circle.svg"
                            alt="about-icon">
                        <img class="sidebar-icon-selected" src="./assets/images/sidebar/sparkle-circle-selected.svg"
                            alt="dashboard-icon">
                    </span>
                    <span *ngIf="!isCollapsed" class="menu-content pl-1">What's New</span>
                </li> -->

                <li nz-submenu [nzTitle]="title" (click)="isOpen = !isOpen">
                    <ng-template #title>
                        <img class="sidebar-icon mr-1" src="./assets/images/dashboard-icons/support-icon.svg"
                            alt="about-icon">
                        <img class="sidebar-icon-selected" src="./assets/images/sidebar/support-selected.svg"
                            alt="dashboard-icon">
                        <span class="mt-1" *ngIf="!isCollapsed">Support</span>
                    </ng-template>
                    <ul class="sidenav-lis-items" *ngIf="isOpen">
                        <li nz-menu-group nzTitle="How can we help you?" class="feedback-title">
                            <ul>
                                <li nz-menu-item class="feedback-menu" [routerLink]="['/support/support-video']"
                                    (click)="isOpen = !isOpen" [nzSelected]="isActiveRoute('/support/support-video')">
                                    <span class="mr-2"><img src="./assets/images/video-play-icon.svg"
                                            alt="feedback-icon" class="submenu-icon filter_darkmode"></span>
                                    <span class="pt-2">How-to videos</span>
                                </li>
                                <li nz-menu-item class="feedback-menu" nzMatchRouter (click)="closeSubMenu()">
                                    <span class="mr-2"><img src="./assets/images/sidebar/question-circle.svg"
                                            alt="feedback-icon" class="submenu-icon filter_darkmode"></span>

                                    <a href="https://help.displaynow.io" target="_blank" class="text-black"
                                        class="pt-2">Help
                                        center</a>

                                    <span class="mr-2 right-icon-container"><img src="./assets/images/sidebar/open.svg"
                                            alt="feedback-icon" class="submenu-icon"></span>
                                </li>
                                <li nz-menu-item class="feedback-menu" (click)="openFeedbackModal()" nzMatchRouter>
                                    <span><img src="./assets/images/sound.png" alt="feedback-icon"
                                            class="submenu-icon mr-2 filter_darkmode"></span>
                                    <span class="pt-2">Feedback</span>
                                </li>
                            </ul>
                        </li>
                        <li nz-menu-group nzTitle="Addtional Support" class="feedback-title">
                            <ul>
                                <li nz-menu-item class="feedback-menu" nzMatchRouter (click)="closeSubMenu()">
                                    <span class="mr-2"><img src="./assets/images/sidebar/mail.svg" alt="feedback-icon"
                                            class="submenu-icon filter_darkmode"></span>

                                    <a href="https://displaynow.io/contact" target="_blank" class="text-black"
                                        class="pt-2">Contact us</a>

                                    <span class="mr-2 right-icon-container"><img src="./assets/images/sidebar/open.svg"
                                            alt="feedback-icon" class="submenu-icon"></span>
                                </li>
                                <li nz-menu-item class="feedback-menu" nzMatchRouter (click)="closeSubMenu()">
                                    <span class="mr-2"><img src="./assets/images/sidebar/phone.svg" alt="feedback-icon"
                                            class="submenu-icon filter_darkmode"></span>
                                    <a [href]="bookDemo" target="_blank" class="text-black" class="pt-2">Book a demo</a>

                                    <span class="mr-2 right-icon-container"><img src="./assets/images/sidebar/open.svg"
                                            alt="feedback-icon" class="submenu-icon"></span>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>
            <div *ngIf="!isReseller && !isReferredUser">
                <div class="pl-2 pr-2">
                    <hr>
                </div>
                <ul class="pl-1 ml-3" *ngIf="isCollapsed">
                    <div class="copyright-text-color"><span nz-icon nzType="copyright" nzTheme="outline"></span></div>
                </ul>
                <ul class="pl-1 ml-2" *ngIf="!isCollapsed">
                    <div class="copyright-text-color">DUKE TECHNOLOGIES LLC.</div>
                    <div class="copyright-text-color"><span nz-icon nzType="copyright" class="copyright-icon"
                            nzTheme="outline"></span> 2024 All Rights
                        Reserved.</div>
                </ul>
            </div>
            <div class="mt-2" *ngIf="!isCollapsed && (isReseller || isReferredUser) && logoUrl">
                <nz-divider nzOrientation="right" class="sidebar-divider"></nz-divider>
                <div class="bottom-logo-container">
                    <div class="pl-3">Powered by:</div>
                    <div class="sidebar-logo">
                        <a [routerLink]="['/dashboard']">
                            <img class="lightmode_img" src="./assets/images/sidebar/logo-black.svg" alt="logo">
                            <img class="darkmode_img" src="./assets/images/sidebar/logo_ligth.svg" alt="logo">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </nz-sider>

    <nz-drawer [nzClosable]="false" [nzVisible]="visible" [nzPlacement]="placement" *ngIf="mobileOne"
        nzMaskClosable="true" (nzOnClose)="close()">
        <ng-container *nzDrawerContent>
            <div class="drawer-menu">
                <div nz-row>
                    <div nz-col nzSpan="21">
                        <div class="sidebar-logo">
                            <a [routerLink]="['/welcome']" target="_blank">
                                <img class="lightmode_img" src="./assets/images/sidebar/logo-black.svg" alt="logo">
                                <img class="darkmode_img" src="./assets/images/sidebar/logo_ligth.svg" alt="logo">
                            </a>
                        </div>
                    </div>
                    <div nz-col nzSpan="3" class="text-right align-items-center">
                        <span nz-icon nzType="close-circle" nzTheme="outline" (click)="close()"></span>
                    </div>
                </div>

                <div class="side-bar-input">
                    <form nz-form>
                        <nz-form-item>
                            <nz-form-control class="header-input">
                                <span class="select-prefix-icon">
                                    <img class="sidebar-icon" src="./assets/images/dashboard-icons/team.svg"
                                        alt="dashboard-icon">
                                </span>
                                <nz-select [(ngModel)]="selectedGroupId" #selectedGroup="ngModel" name="selectedGroup"
                                    class="header-input" nzPlaceHolder="Search Groups" [disabled]="disableGroup"
                                    (ngModelChange)="onSubscriptionPlanChanged()">
                                    <nz-option [nzValue]="0" [nzLabel]="'Groups (All)'"></nz-option>
                                    <nz-option *ngFor="let item of subscriptionPlans" [nzValue]="item.keyInt"
                                        [nzLabel]="item.value"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </form>
                </div>

                <ul nz-menu nzMode="inline">
                    <li *ngIf="!isTrialTimeEnded" nz-menu-item [routerLink]="['/welcome']"
                        [nzSelected]="isActiveRoute('/welcome') || isActiveRoute('/', true)" (click)="close()">
                        <span>
                            <img class="sidebar-icon" src="./assets/images/sidebar/welcome.svg" alt="dashboard-icon">
                            <img class="sidebar-icon-selected" src="./assets/images/sidebar/welcome-selected.svg"
                                alt="dashboard-icon">
                        </span>
                        <span>Welcome</span>
                    </li>
                    <li nz-menu-item [routerLink]="['/dashboard']" [nzSelected]="isActiveRoute('/dashboard')"
                        (click)="close()">
                        <span>
                            <img class="sidebar-icon" src="./assets/images/sidebar/dashboard.svg" alt="dashboard-icon">
                            <img class="sidebar-icon-selected" src="./assets/images/sidebar/dashboard-selected.svg"
                                alt="dashboard-icon">
                        </span>
                        <span>Dashboard</span>
                    </li>
                    <li nz-menu-item [routerLink]="['/display/manage']" (click)="close()"
                        [nzSelected]="isActiveRoute('/displays/')">
                        <span>
                            <img class="sidebar-icon" src="./assets/images/sidebar/screen.svg" alt="screen-icon">
                            <img class="sidebar-icon-selected" src="./assets/images/sidebar/screen-selected.svg"
                                alt="dashboard-icon">
                        </span>
                        <span>Screens</span>
                    </li>
                    <li nz-menu-item (click)="close()" [routerLink]="['/media-app/manage']"
                        [nzSelected]="isActiveRoute('/media-app/manage', true) || isActiveRoute('/user-media-app')">
                        <span>
                            <img class="sidebar-icon" src="./assets/images/sidebar/media.svg" alt="media-icon">
                            <img class="sidebar-icon-selected" src="./assets/images/sidebar/media-selected.svg"
                                alt="dashboard-icon">
                        </span>
                        <span>My Media</span>
                    </li>
                    <li nz-menu-item [routerLink]="['/media-app/store']" (click)="close()"
                        [nzSelected]="isActiveRoute('/media-app/store', true)">
                        <span>
                            <img class="sidebar-icon" src="./assets/images/sidebar/media-apps.svg" alt="media-icon">
                            <img class="sidebar-icon-selected" src="./assets/images/sidebar/media-apps-selected.svg"
                                alt="dashboard-icon">
                        </span>
                        <span>Media App Store</span>
                    </li>
                    <li nz-menu-item (click)="close()" [routerLink]="['/display-list/manage']"
                        [nzSelected]="isActiveRoute('/display-list')">
                        <span>
                            <img class="sidebar-icon" src="./assets/images/sidebar/playlist.svg" alt="playlist-icon">
                            <img class="sidebar-icon-selected" src="./assets/images/sidebar/playlist-selected.svg"
                                alt="dashboard-icon">
                        </span>
                        <span>Playlists</span>
                    </li>
                    <li nz-menu-item (click)="close()" [routerLink]="['/broadcast/manage']"
                        [nzSelected]="isActiveRoute('/broadcast')">
                        <span>
                            <img class="sidebar-icon" src="./assets/images/sidebar/broadcast.svg" alt="broadcast-icon">
                            <img class="sidebar-icon-selected" src="./assets/images/sidebar/broadcast-selected.svg"
                                alt="dashboard-icon">
                        </span>
                        <span>Broadcasts</span>
                    </li>
                    <li nz-menu-item (click)="close()" [routerLink]="['/group/manage']"
                        [nzSelected]="isActiveRoute('/group')">
                        <span>
                            <img class="sidebar-icon" src="./assets/images/sidebar/group.svg" alt="group-icon">
                            <img class="sidebar-icon-selected" src="./assets/images/sidebar/group-selected.svg"
                                alt="dashboard-icon">
                        </span>
                        <span>Groups</span>
                    </li>
                    <li nz-menu-item (click)="close()" [routerLink]="['/subscription/manage']"
                        [nzSelected]="isActiveRoute('/subscription')" *ngIf="!isReseller">
                        <span>
                            <img class="sidebar-icon" src="./assets/images/sidebar/subscription.svg"
                                alt="subscription-icon">
                            <img class="sidebar-icon-selected" src="./assets/images/sidebar/subscription-selected.svg"
                                alt="dashboard-icon">
                        </span>
                        <span>My Subscription</span>
                    </li>
                    <li nz-menu-item (click)="close()" [routerLink]="['/reseller']"
                        [nzSelected]="isActiveRoute('/reseller')" *ngIf="isReseller">
                        <span>
                            <img class="sidebar-icon" src="./assets/images/sidebar/subscription.svg"
                                alt="subscription-icon">
                            <img class="sidebar-icon-selected" src="./assets/images/sidebar/subscription-selected.svg"
                                alt="dashboard-icon">
                        </span>
                        <span>Reseller</span>
                    </li>

                    <li nz-menu-item (click)="close()" [routerLink]="['/folder/manage']"
                        [nzSelected]="isActiveRoute('/folder')">
                        <span>
                            <img class="sidebar-icon" src="./assets/images/sidebar/folder.svg" alt="folder-icon">
                            <img class="sidebar-icon-selected" src="./assets/images/sidebar/folder-selected.svg"
                                alt="dashboard-icon">
                        </span>
                        <span>Folders</span>
                    </li>
                </ul>

                <!-- <ul nz-menu nzMode="vertical" class="pl-1">
                    <li nz-menu-item (click)="close()">
                        <span class="pl-1">
                            <img class="sidebar-icon" src="./assets/images/sidebar/sparkle-circle.svg" alt="about-icon">
                            <img class="sidebar-icon-selected" src="./assets/images/sidebar/sparkle-circle-selected.svg"
                                alt="dashboard-icon">
                        </span>
                        <span>What's New</span>
                    </li>
                </ul> -->

                <ul nz-menu nzMode="horizontal" class="pl-1">
                    <li nz-submenu [nzTitle]="title">
                        <ng-template #title>
                            <img class="sidebar-icon mr-1" src="./assets/images/dashboard-icons/support-icon.svg"
                                alt="about-icon">
                            <img class="sidebar-icon-selected mr-1" src="./assets/images/sidebar/support-selected.svg"
                                alt="dashboard-icon">
                            Support
                            <span nz-icon nzType="right" nzTheme="outline" class="support-arrow"></span>
                        </ng-template>
                        <ul>
                            <li nz-menu-item [routerLink]="['/support/support-video']"
                                [nzSelected]="isActiveRoute('/support/support-video')" (click)="close()">How-to videos
                            </li>
                            <li nz-menu-item nzMatchRouter>
                                <a href="https://help.displaynow.io" target="_blank" class="text-black"
                                    class="pt-2">Help
                                    center</a>
                            </li>
                            <li nz-menu-item (click)="openFeedbackModal()" nzMatchRouter>
                                Feedback
                            </li>
                            <li nz-menu-item><a href="https://displaynow.io/contact" target="_blank"
                                    class="text-black">Contact
                                    us</a>
                            </li>
                            <li nz-menu-item nzMatchRouter>
                                <a [href]="bookDemo" target="_blank" class="text-black" class="pt-2">Book a demo</a>
                            </li>

                        </ul>
                    </li>
                </ul>

                <div nz-row class="mt-3">
                    <div nz-col nzSpan="24" class="p-3">
                        <button nz-button nzType="primary" (click)="upgradeToProPlan()" class="btn-full">
                            <span nz-icon nzType="crown" nzTheme="fill" class="mr-1"></span>Upgrade to pro
                        </button>
                    </div>
                </div>

                <ul class="pl-1 ml-3 pb-3" *ngIf="!isReseller && !isReferredUser">
                    <div class="pr-3">
                        <hr>
                    </div>
                    <div class="copyright-text-color">DUKE TECHNOLOGIES LLC.</div>
                    <div class="copyright-text-color"><span nz-icon nzType="copyright" nzTheme="outline"
                            class="copyright-icon"></span>
                        <span class="fs-12"> 2024 All Rights Reserved. </span>
                    </div>
                </ul>
            </div>
        </ng-container>
    </nz-drawer>
    <nz-layout>
        <nz-header class="header">
            <div class="header-content">
                <div class="d-flex align-items-center">
                    <div class="ml-4" *ngIf="mobileOne">
                        <span nz-icon nzType="menu" (click)="open()" class="cursor-pointer"></span>
                    </div>

                    <div class="mob-sidebar-logo " *ngIf="mobileOne">
                        <a [routerLink]="['/welcome']">
                            <img class="lightmode_img" src="./assets/images/sidebar/logo-black.svg" alt="logo">
                            <img class="darkmode_img" src="./assets/images/sidebar/logo_ligth.svg" alt="logo">
                        </a>
                    </div>
                </div>
                <div class="d-flex justify-content-end align-items-center">

                    <!-- <div *ngIf="mobileOne" class="mt-1">
                        <span nz-icon nzType="search" nzTheme="outline"></span>
                    </div>
                    <nz-divider class="mr-3 ml-3  mt-1" nzType="vertical" *ngIf="mobileOne"></nz-divider> -->

                    <!-- <div class="search-bar" *ngIf="webOne">
                        <form nz-form>
                            <nz-form-item>
                                <nz-form-control class="header-input">
                                    <nz-input-group nzPrefixIcon="search">
                                        <input nz-input
                                            placeholder="Search for screen, media, playlist and broadcast" />
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                        </form>
                    </div> -->
                    <!-- <nz-divider class="mr-3 ml-3 mt-1" nzType="vertical"
                        *ngIf="webOne && (isModelLoaded && !subscriptionSummary.isProPlan)"></nz-divider> -->
                    <div class="pt-2" *ngIf="webOne && (isModelLoaded && !subscriptionSummary.isProPlan)">
                        <button nz-button nzType="primary" (click)="upgradeToProPlan()">
                            <span nz-icon nzType="crown" nzTheme="fill" class="mr-1"></span>Upgrade to pro
                        </button>
                    </div>
                    <nz-divider class="mr-3 ml-3 mt-1" nzType="vertical"
                        *ngIf="webOne && (isModelLoaded && !subscriptionSummary.isProPlan)"></nz-divider>
                    <!-- <div class="mr-3 pt-1">
                        <span nz-icon nzType="bell" class="header-icon"></span>
                    </div> -->
                    <div>
                        <div nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu"
                            (nzVisibleChange)="onVisibleChange($event)">
                            <div class="d-flex align-items-center cursor-pointer">
                                <div class="profile-holder fs-16 text-white" *ngIf="!profilePicture">
                                    {{currentUserInitials}}</div>
                                <div class="profile-holder fs-16 text-white" *ngIf="profilePicture">
                                    <img [src]="profilePicture">
                                </div>
                                <span *ngIf="webOne">{{currentUsername}}</span>
                                <span nz-icon [nzType]="isDropdownVisible ? 'right' : 'down'"
                                    class="profile-icon ml-2"></span>
                            </div>
                        </div>
                        <nz-dropdown-menu #menu="nzDropdownMenu">
                            <ul nz-menu [nzMode]="'vertical'">
                                <li nz-menu-item [routerLink]="['/profile/manage']">
                                    <span class="mr-2">
                                        <img class="filter_darkmode" src="./assets/images/sidebar/profile.svg"
                                            alt="theme-icon">
                                    </span>
                                    My Profile
                                </li>
                                <li nz-submenu [nzTitle]="menuItem">
                                    <ng-template #menuItem>
                                        <div class="d-flex align-items-center">
                                            <img src="./assets/images/sidebar/appearance.svg" *ngIf="!isDarkMode"
                                                class="sidebar-icon mr-2 filter_darkmode" alt="feedback-icon">
                                            <img src="./assets/images/sidebar/dark-mode.svg" *ngIf="isDarkMode"
                                                class="sidebar-icon mr-2 filter_darkmode" alt="theme-icon">
                                            <span class="pl-1">Appearance</span>
                                        </div>
                                    </ng-template>
                                    <ul>
                                        <li nz-menu-item (click)="activeDarkMode()"><span>
                                                <img src="./assets/images/sidebar/dark-mode.svg"
                                                    class="sidebar-icon mr-2 filter_darkmode" alt="theme-icon">
                                            </span>Dark Mode</li>
                                        <li nz-menu-item (click)="activeLightMode()">
                                            <span>
                                                <img src="./assets/images/sidebar/appearance.svg"
                                                    class="sidebar-icon mr-2 filter_darkmode" alt="theme-icon">
                                            </span>Light Mode
                                        </li>
                                    </ul>
                                </li>
                                <li nz-menu-item [routerLink]="['/account/logout']">
                                    <span class="mr-2">
                                        <img src="./assets/images/sidebar/sign-out.svg" alt="theme-icon"
                                            class="filter_darkmode">
                                    </span>
                                    <span class="pl-1">Sign Out</span>
                                </li>
                            </ul>
                        </nz-dropdown-menu>
                    </div>
                </div>
            </div>
        </nz-header>
        <nz-content [ngClass]="isCollapsed ? 'right-layout-m-80' : 'right-layout-m-250'">
            <div class="inner-content">
                <router-outlet></router-outlet>
            </div>
        </nz-content>

        <nz-modal [(nzVisible)]="isVisible" [nzTitle]="modalTitle" (nzOnCancel)="closeModal()"
            [nzContent]="modalContent" [nzFooter]="null">
            <ng-template class="fw-bold" #modalTitle>Help us improve!</ng-template>

            <ng-template #modalContent>
                <app-feedback (closeModal)="closeModal()"></app-feedback>
            </ng-template>
        </nz-modal>
    </nz-layout>
</nz-layout>